<template>
  <div class="bg-white" id="LoginWrap">
    <div class="login_btn_box m-auto text-center">
      <CLink to="/home">
        <CImg src="/img/threespace/logo/logo_3space_new@2x.png" width="200px" />
      </CLink>

      <CRow class="justify-content-center mt-5">
        <div class="col-12 text-center">
          <div class="c-avatar c-avatar-xl mr-5">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/kakao_logo_100x100.png"
              @click="onJoinKakao()"
            />
          </div>
          <div class="c-avatar c-avatar-xl mr-5">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/naver_logo_180x180.png"
              @click="onJoinNaver()"
            />
          </div>
          <div class="c-avatar c-avatar-xl">
            <img
              class="c-avatar-img cursor-pointer"
              src="/img/threespace/company/google_logo_100x100.jpg"
              @click="onJoinGoogle()"
            />
          </div>
        </div>
      </CRow>

      <div class="or_line">
        <div class="text">OR</div>
      </div>

      <CRow class="justify-content-center">
        <CCol class="col-12 text-left">
          <CInput
            :lazy="false"
            :value.sync="$v.form.account.$model"
            :isValid="checkIfValid('account')"
            :placeholder="$t('login.email')"
            autocomplete="email"
            :invalidFeedback="$t('validation.requiredEmail')"
            class="mb-2"
          />
          <CInput
            type="password"
            :lazy="false"
            :value.sync="$v.form.password.$model"
            :isValid="checkIfValid('password')"
            :placeholder="$t('login.password')"
            autocomplete="new-password"
            :invalidFeedback="$t('validation.requiredPassword')"
            class="mb-2"
          />
          <CInput
            type="password"
            :value.sync="$v.form.confirmPassword.$model"
            :isValid="checkIfValid('confirmPassword')"
            :placeholder="$t('login.confirmPassword')"
            autocomplete="new-password"
            :invalidFeedback="$t('validation.requiredPassword')"
            class="mb-2"
          />
          <CInput
            :lazy="false"
            :value.sync="$v.form.nickname.$model"
            :isValid="checkIfValid('nickname')"
            :placeholder="$t('login.nickname')"
            autocomplete="username"
            :invalidFeedback="$t('validation.requiredUsername')"
            class="mb-2"
          />
          <CInput
            :lazy="false"
            :value.sync="$v.form.mobile.$model"
            :isValid="checkIfValid('mobile')"
            :placeholder="$t('login.mobile')"
            autocomplete="mobile"
            :invalidFeedback="$t('validation.onlyNumeric')"
            class="mb-2"
          />
          <div class="border-dark p-2">
            <table class="table text-left table-borderless mb-0">
              <tr>
                <td colspan="2">
                  <CInputCheckbox
                    :label="$t('login.allAgree')"
                    :checked.sync="acceptCheckTotal"
                    custom
                    @change="onCheckTotal"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <CInputCheckbox
                    :label="$t('login.termsService')"
                    :checked.sync="$v.form.acceptTermsService.$model"
                    :isValid="checkIfValid('acceptTermsService')"
                    :invalidFeedback="$t('validation.required')"
                    custom
                    @change="onCheckItem"
                  />
                </td>
                <td class="text-right">
                  <a
                    class="cursor-pointer"
                    @click="showTerms('terms_of_service')"
                  >
                    {{ $t('login.more') }}
                    <font-awesome-icon icon="exclamation-circle" />
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <CInputCheckbox
                    :label="$t('login.privacyPolicy')"
                    :checked.sync="$v.form.acceptPrivacyPolicy.$model"
                    :isValid="checkIfValid('acceptPrivacyPolicy')"
                    :invalidFeedback="$t('validation.required')"
                    custom
                    @change="onCheckItem"
                  />
                </td>
                <td class="text-right">
                  <a class="cursor-pointer" @click="showTerms('privacy_policy')">
                    {{ $t('login.more') }}
                    <font-awesome-icon icon="exclamation-circle" />
                  </a>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <CInputCheckbox
                    :label="$t('login.promotion')"
                    :checked.sync="form.acceptPromotion"
                    custom
                    @change="onCheckItem"
                  />
                </td>
              </tr>
            </table>
          </div>
        </CCol>
      </CRow>

      <p class="text-danger mt-3">{{ errorMessage }}</p>
      <b-button block class="space_btn" @click="onJoinSubmit()">
        {{ $t("login.signUp") }}
      </b-button>
    </div>

    <!-- 회원가입 완료 -->
    <b-modal
      id="joinSuccessModal"
      ref="joinSuccessModal"
      hide-header
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      body-class="text-center py-5"
    >
      <b-img src="/img/threespace/logo/logo_100x100.png"></b-img>
      <p class="font-lg font-weight-bold">
        {{ $t("login.join.successJoin") }}
      </p>
      <p>
        {{ $t("login.join.completeJoin") }}
      </p>
      <b-button
        squared
        variant="black"
        class="text-white mt-3 px-5"
        @click="onClickDrops"
      >
        {{ $t("button.gotoGallery") }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
  helpers,
  sameAs,
  numeric
} from "vuelidate/lib/validators";
import { v4 as uuidv4 } from "uuid";
import { mapState, createNamespacedHelpers } from "vuex";
const authHelper = createNamespacedHelpers("auth");

export default {
  name: "Join",
  metaInfo() {
    return {
      title: this.$t("meta.join.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.join.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.join.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.join.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.join.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data() {
    return {
      isBusy: false,
      form: {
        account: null,
        password: null,
        confirmPassword: null,
        nickname: null,
        mobile: null,
        acceptTermsService: false,
        acceptPrivacyPolicy: false,
        acceptPromotion: false,
        referral: null
      },
      acceptCheckTotal: false,
      errorMessage: null
    };
  },
  computed: {
    ...mapState({
      errors: state => {
        return state.auth.errors || {};
      }
    }),
    isValid() {
      return !this.$v.form.$invalid;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    }
  },
  mounted() {
    this.form.referral = localStorage.getItem("referral3spaceJoin");
  },
  mixins: [validationMixin],
  validations: {
    form: {
      account: { required, email },
      password: {
        required,
        minLength: minLength(8),
        strongPass: helpers.regex(
          "strongPass",
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        )
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      },
      nickname: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
        strongPass: helpers.regex("strongPass", /^[a-zA-Z0-9_-]*$/)
      },
      mobile: {
        minLength: minLength(10),
        maxLength: maxLength(12),
        numeric
      },
      acceptTermsService: { required, mustAccept: val => val },
      acceptPrivacyPolicy: { required, mustAccept: val => val }
    }
  },
  methods: {
    ...authHelper.mapActions(["logout", "join"]),
    onCheckTotal() {
      this.form.acceptTermsService = this.acceptCheckTotal;
      this.form.acceptPrivacyPolicy = this.acceptCheckTotal;
      this.form.acceptPromotion = this.acceptCheckTotal;
    },
    onCheckItem() {
      // 전체 동의
      if (
        this.form.acceptTermsService &&
        this.form.acceptPrivacyPolicy &&
        this.form.acceptPromotion
      ) {
        this.acceptCheckTotal = true;
      } else {
        this.acceptCheckTotal = false;
      }
    },
    showTerms(_code) {
      let routeUrl = this.$router.resolve({
        path: "/terms/" + _code
      });
      window.open(routeUrl.href, "_blank");
    },
    onJoinSubmit() {
      this.$v.$touch();
      if (this.isValid) {
        this.logout(); // 로그아웃

        this.join(this.form)
          .then(() => {
            this.errorMessage = null;
            this.$refs["joinSuccessModal"].show();

            // 회원가입 완료후 레퍼럴 정보 삭제
            let referral = localStorage.getItem("referral3spaceJoin");
            if (referral !== undefined && referral !== null && referral !== "") {
              localStorage.removeItem("referral3spaceJoin");
            }
          })
          .catch(() => {
            this.$log.error(this.errors);
            if (
              this.errors.error === "BAD_REQUESTED" &&
              this.errors.resource === "EXISTS_ACCOUNT"
            ) {
              this.errorMessage = this.$t("validation.exist.account");
            } else if (
              this.errors.error === "BAD_REQUESTED" &&
              this.errors.resource === "EXISTS_NICKNAME"
            ) {
              this.errorMessage = this.$t("validation.exist.nickname");
            } else if (
              this.errors.error === "BAD_REQUESTED" &&
              this.errors.resource === "EXISTS_MOBILE"
            ) {
              this.errorMessage = this.$t("validation.exist.mobile");
            } else {
              this.errorMessage = "[" + this.errors.error + "] " + this.$t("login.join.error");
            }
          });
      }
    },
    onJoinKakao() {
      const redirect_uri = process.env.VUE_APP_BACKEND_API_URL + "/threespace/v1/join/kakao/callback";
      let url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;
      if (this.form.referral !== undefined && this.form.referral !== null && this.form.referral !== "") {
        // REDIRECT_URI에 파라미터 추가 불가, 미사용 파라미터 활용
        url = url + "&state=" + this.form.referral;
      }

      window.location.href = url;
    },
    onJoinNaver() {
      let redirect_uri = process.env.VUE_APP_BACKEND_API_URL + "/threespace/v1/join/naver/callback?referral=";
      if (this.form.referral !== undefined && this.form.referral !== null && this.form.referral !== "") {
        redirect_uri = redirect_uri + this.form.referral;
      }
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;

      window.location.href = url;
    },
    onJoinGoogle() {
      const redirect_uri = process.env.VUE_APP_BACKEND_API_URL + "/threespace/v1/join/google/callback";
      let url =
        `https://accounts.google.com/o/oauth2/v2/auth?client_id=713701466995-jef2uanlhjor7gbe5e15c20fodnar88u.apps.googleusercontent.com&redirect_uri=${redirect_uri}&response_type=code`
          + `&scope=openid%20email%20profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuser.phonenumbers.read&access_type=offline`;
      if (this.form.referral !== undefined && this.form.referral !== null && this.form.referral !== "") {
        // REDIRECT_URI에 파라미터 추가 불가, 미사용 파라미터 활용
        url = url + "&state=" + this.form.referral;
      }

      window.location.href = url;
    },
    onJoinKakao_bk() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/join/kakao/callback";
      const url = `https://kauth.kakao.com/oauth/authorize?client_id=80e3176f5940dbb2f27f3b44d5fd124d&redirect_uri=${redirect_uri}&response_type=code`;
      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=500, height=600, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    onJoinNaver_bk() {
      const redirect_uri =
        process.env.VUE_APP_BACKEND_API_URL +
        "/threespace/v1/join/naver/callback";
      const state = uuidv4();
      const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9kK4tH7jbFwIgYMBleCG&redirect_uri=${redirect_uri}&state=${state}`;
      window.app = this;
      window.open(
        url,
        "popup",
        "top=10, left=10, width=800, height=700, status=no, menubar=no, toolbar=no, resizable=no"
      );
    },
    onClickDrops() {
      this.$router.push({ name: "Drops" });
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    }
  }
};
</script>
